@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/themes/camfil/imports";

$name: '.ContactArea';

#{$name} {
    @media print {
        display: none;
    }

    position: relative;
    z-index: $zindex-fixed;

    &-expandButton {
        background-color: $primary;
        color: $white;
        transform: rotate(-90deg) translate(50%, 0);
        transform-origin: 100% 0;
        padding: 5px 15px 5px 30px;
        display: block;
        right: 24px;
        top: 50%;
        position: fixed;
        line-height: 1;
        cursor: pointer;
        margin: 0;

        @include media-breakpoint-up(sm) {
            right: 34px;
            padding: 10px 20px 10px 35px;
        }

        &:focus, &:hover, &:active {
            color: $white;
            text-decoration: none;
        }

        &::before {
            position: absolute;
            top: 5px;
            left: 6px;
            content: "";
            @include icon-arrow-circle-up($white);
            display: inline-block;
            height: 15px;
            width: 15px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;

            @include media-breakpoint-up(sm) {
                top: 9px;
                left: 8px;
                height: 17px;
                width: 17px;
            }
        }
    }

    &-large {
        background-color: $white;
        right: 0;
        top: 50%;
        position: fixed;
        transform: translate(0, -50%);
        width: 200px;
        max-height: 100vh;
        overflow-y: auto;
        z-index: 10;
        box-shadow: $box-shadow-lg;
    }

    &-toggle {
        right: -40px;
        top: 50%;
        position: fixed;

        & ~ #{$name}-large {
            right: -200px;
            transition: right 300ms cubic-bezier(.17, .04, .03, .94);
        }

        &:checked ~ #{$name}-large {
            right: 0;
        }

        &:checked ~ #{$name}-expandButton {
            display: none;
        }
    }

    &-expandButtonLarge {
        background-color: $primary;
        color: $white;
        display: block;
        padding: 10px 20px 10px 35px;
        text-align: center;
        position: relative;
        cursor: pointer;

        &::before {
            content: "";
            @include icon-remove($white);
            display: inline-block;
            height: 15px;
            width: 15px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            position: absolute;
            left: 12px;
            top: 50%;
            margin-top: -7px;
        }
    }

    &-inner {
        font-size: .875rem;

        > div {
            border-bottom: 1px solid $border-color;

            &:last-child {
                border: 0;
            }
        }
    }

    &-phoneNumber {
        position: relative;
        padding-left: 25px;

        &::before {
            position: absolute;
            left: 0;
            top: 5px;
            content: "";
            @include icon-phone($primary);
            display: inline-block;
            height: 15px;
            width: 15px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
        }
    }

    &-email {
        position: relative;
        padding-left: 25px;

        &::before {
            position: absolute;
            left: 0;
            top: 9px;
            content: "";
            @include icon-envelope($primary);
            display: inline-block;
            height: 15px;
            width: 15px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
        }
    }
}
